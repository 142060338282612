var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"abvvvv"},[_c('a-row',{attrs:{"gutter":16}},_vm._l((_vm.stockDayLine),function(stockItem,index){return _c('a-col',{key:index,attrs:{"span":3}},[_c('a-card',{attrs:{"size":"small"},on:{"click":function($event){return _vm.clk(stockItem.stock_code)}}},[_c('ve-candle',{attrs:{"legend-visible":false,"data":stockItem.stock_chart,"settings":_vm.chartStockSettings,"title":{
                          text:("" + (stockItem.stock_name)),
                          left: '100',     // 水平位置自动调整
                          bottom: '-10',      // 底部居中
                          textBaseline: 'middle', // 文本垂直居中对齐
                          textAlign: 'center',   // 文本水平居中对齐
                          textStyle: {
                              fontSize: 9.5,
                          }
                },"judge-width":"","width":"220px","height":"120px"}})],1)],1)}),1)],1),_c('a-divider',[_vm._v("昨日涨停")]),_c('div',{staticClass:"abvvvv"},[_c('a-row',{attrs:{"gutter":16}},_vm._l((_vm.liuruChart),function(stockLiuru,index){return _c('a-col',{key:index,attrs:{"span":6}},[_c('a-card',{attrs:{"size":"small"}},[_c('ve-histogram',{attrs:{"data":stockLiuru,"legend":{show:true,bottom:10},"grid":{right: '5%'},"mark-line":_vm.markLine,"title":{
                      text:("(" + (stockLiuru.title?stockLiuru.title:'0') + "）"),
                      textStyle: {
                          fontSize: 15,
                      },
                      subtextStyle: {
                          fontSize: 12.5
                      }
                  },"settings":{
                      label: { show: true, position: 'top'},
                      yAxisType: ['KMB', 'KMB'],
                      showLine: ['流入'], 
                      axisSite: {}, 
                  },"extend":_vm.histogramChartExtend,"judge-width":"","width":"420px","height":"260px"}})],1)],1)}),1)],1),_c('a-modal',{attrs:{"cancelText":"取消","okText":"查看K线","width":"1350px"},on:{"ok":_vm.handleOk},model:{value:(_vm.editVisible),callback:function ($$v) {_vm.editVisible=$$v},expression:"editVisible"}},[_c('vue-element-loading',{attrs:{"active":_vm.loadingP,"color":"#00cca2","spinner":"spinner"}}),_c('ve-histogram',{attrs:{"data":_vm.predictChart,"legend":{show:true,bottom:10},"judge-width":"","grid":{right: '5%'},"mark-line":_vm.markLine,"title":{
                    text:("(" + (_vm.predictChart.title?_vm.predictChart.title:'0') + "）"),
                    textStyle: {
                        fontSize: 15,
                    },
                    subtextStyle: {
                        fontSize: 12.5
                    }
                },"settings":{
                    label: { show: true, position: 'top'},
                    yAxisType: ['KMB', 'KMB'], 
                    showLine: ['超大'], 
                    axisSite: {}, 
                },"extend":_vm.histogramChartExtend}}),_c('a-table',{attrs:{"size":"small","pagination":false,"bordered":false,"rowKey":"id","columns":_vm.columns,"dataSource":_vm.liuruList},scopedSlots:_vm._u([{key:"index",fn:function(text, record , index){return [_c('span',[_vm._v(_vm._s((index+1)))])]}},{key:"price",fn:function(text, record , index){return [_c('span',{style:(_vm.color(text))},[_vm._v(" "+_vm._s((_vm.formatNumber(text))))])]}}])}),[_c('a-timeline',_vm._l((_vm.predictMsg),function(msg,index){return _c('a-timeline-item',{key:index,attrs:{"color":msg.color}},[_vm._v(_vm._s(msg.stock_date)+" "+_vm._s(msg.tm)+" "+_vm._s(msg.msg))])}),1)],(_vm.first>0 && _vm.predictChart.rows.length == 0)?_c('LEmpty'):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }